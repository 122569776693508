import {React}  from 'react';
import {FaEnvelope} from 'react-icons/fa';

const Footer = () => {
    return (
      <footer className="text-[black] py-4 bg-[#17b257]" >
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div>
        <p>PBM Construction  &copy; 2024 | License #: PBMCOCL801RM</p>
        <p>Phone: (425) 502-0236</p>
        </div>
        <div className='items-end'>
        <a href="mailto:Constructionpbm@gmail.com" className="hover:text-gray-800">
          <FaEnvelope size={21}/>
        </a>
        </div>
      </div>
      </footer>
    );
};

export default Footer;
